import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ConfigProvider, Button, Layout, Card } from 'antd';
import AppRoutes from './Components/Routing';
 
const lightTheme = {
  token: {
    colorPrimary: '#00827e',
    borderRadius: 2,
    colorBgContainer: '#ffffff',
    colorText: '#000000',
    colorSecondary: '#ff9e18',
  },
};

const darkTheme = {
  token: {
    colorPrimary: '#00827e',
    borderRadius: 2,
    colorBgContainer: '#1f1f1f',
    colorText: '#ffffff',
    colorSecondary: '#ff9e18',
  },
};

const App: React.FC = () => {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme ? JSON.parse(savedTheme) : false;
  });

  useEffect(() => {
    localStorage.setItem('theme', JSON.stringify(isDarkMode));
  }, []); 

  return (
    <ConfigProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <Router>
        <AppRoutes />
      </Router>
    </ConfigProvider>
  );
};

export default App;