// FileUpload.tsx
import React from 'react';
import { InboxOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { message, Upload } from 'antd';

const { Dragger } = Upload;

interface FileUploadProps {
  id: string;
}

const FileUpload: React.FC<FileUploadProps> = ({ id }) => {
  const token = JSON.parse(localStorage.getItem('tokens') || '[]')[0]; 
  const props: UploadProps = {
    name: 'file',
    multiple: true,
    action: 'https://dylo-app.dylo-microservice.com/api/upload/field',
    headers: {
      'Authorization': `Bearer ${token}`
    },
    data: (file) => ({
      id,
      name: file.name,
    }),
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
        window.location.reload();
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  return (
    <Dragger {...props}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Click or drag file to this area to upload</p>
      <p className="ant-upload-hint">
        Support for a single or bulk upload. Strictly prohibited from uploading company data or other
        banned files.
      </p>
    </Dragger>
  );
};

export default FileUpload;