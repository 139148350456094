import React, { useEffect, useState } from 'react';
import { Button, Card, DatePicker, Input, Select, Space, Spin, Tooltip } from 'antd';
import type { DatePickerProps, SelectProps } from 'antd';
import { FileExcelTwoTone } from '@ant-design/icons';
import axios from 'axios';
import moment from 'moment';

const { Option } = Select;
const { Search } = Input;
const url = process.env.REACT_APP_API_URL; 

interface ReportsFiltersProps {
    datePickerValue: any;
    setDatePickerValue: (value: any) => void;
    selectedCarriers: string[];
    setSelectedCarriers: (value: string[]) => void;
    searchValue: string;
    setSearchValue: (value: string) => void;
    loading: boolean;
    generateReport: () => void;
}

interface Carrier {
    name: string;
    id: string;
}

const ReportsFilters: React.FC<ReportsFiltersProps> = ({
    datePickerValue,
    setDatePickerValue,
    selectedCarriers,
    setSelectedCarriers,
    searchValue,
    setSearchValue,
    loading,
    generateReport
}) => {
    const [carriers, setCarriers] = useState<Carrier[]>([]);

    useEffect(() => {
        const fetchCarriers = async () => {
            try {
                const response = await axios.get('https://dylo-app.dylo-microservice.com/api/carriers');
                setCarriers(response.data);
            } catch (error) {
                console.error('Error fetching carriers:', error);
            }
        };

        fetchCarriers();
    }, []);

    const onChange: DatePickerProps['onChange'] = (date, dateString) => {
        if (date) {
            setDatePickerValue(date);
        } else {
            setDatePickerValue(null);
        }
    };

    const carrierOptions = carriers.map((carrier: Carrier) => ({
        label: carrier.name,
        value: carrier.id
    }));

    const sharedProps: SelectProps = {
        mode: 'multiple',
        style: { width: '200px' },
        maxTagCount: 'responsive'
    };

    const carrierSelectProps: SelectProps = {
        ...sharedProps,
        options: carrierOptions,
        value: selectedCarriers,
        onChange: setSelectedCarriers,
        placeholder: 'Select Carrier/s'
    }; 

    return (
        <Spin spinning={loading}>
            <div style={{ marginRight: '25px', marginLeft: '25px' }}>
                <Card>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Space align="baseline" size='large'>
                            <Tooltip placement="top" title={'Generate Report'}>
                                <Button onClick={generateReport} type="primary" icon={<FileExcelTwoTone twoToneColor="#2B9704" />} size={'large'}>
                                    Generate
                                </Button>
                            </Tooltip>
                            <label> <strong> Month: </strong></label>
                            <DatePicker
                                onChange={onChange}
                                picker="month"
                                value={datePickerValue}
                                disabled={loading}
                            />
                            <label> <strong> Carrier/s : </strong></label>
                            <Select {...carrierSelectProps} disabled={loading} />
                        </Space>
                        <Search
                            placeholder="Search"
                            value={searchValue}
                            disabled={loading}
                            onChange={e => setSearchValue(e.target.value)}
                            onSearch={value => console.log(value)}
                            style={{ minWidth: '100px', width: '100%', maxWidth: '300px' }}
                        />
                    </div>
                </Card>
            </div>
        </Spin>
    );
};

export default ReportsFilters;