import React, { useState } from 'react';
import { MenuFoldOutlined, MenuUnfoldOutlined, SunOutlined, MoonOutlined, UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { Button, Layout, Popover, theme } from 'antd';
import { log } from 'console';
import { useNavigate } from 'react-router-dom';

const { Header } = Layout;

interface AppHeaderProps {
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
}

const AppHeader: React.FC<AppHeaderProps> = ({ collapsed, setCollapsed }) => {
  
  const navigate = useNavigate();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const [isDarkMode, setIsDarkMode] = useState(JSON.parse(localStorage.getItem('theme') || 'false'));
  const user = JSON.parse(localStorage.getItem('users') || '{}');
  const toggleTheme = () => {
    const newTheme = !isDarkMode;
    setIsDarkMode(newTheme);
    localStorage.setItem('theme', JSON.stringify(newTheme));
    window.location.reload();
  };

  const LogOut = () => {
    localStorage.removeItem('tokens');
    localStorage.removeItem('users');
    navigate('/login');
  }

  const content = (
    <>
      <p>{user[0]?.name}</p>
      <p>{user[0]?.email}</p>
      <p style={{ width: '100%' }}>
        <Button
          onClick={() => {LogOut()}}
          type="text"
          style={{ width: '100%' }}
          icon={<LogoutOutlined  />} >
          Log-Out
        </Button>
      </p>
    </>
  );

  return (
    <Header style={{ padding: 0, background: colorBgContainer, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button
          type="text"
          icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={() => setCollapsed(!collapsed)}
        />
        <Button
          type="text"
          icon={isDarkMode ? <SunOutlined /> : <MoonOutlined />}
          onClick={toggleTheme}
        />
      </div>
      <Popover placement="bottomRight" title={user.name} content={content} trigger="click">

        <Button
          type="text"
          style={{ marginRight: 30 }}
          icon={<UserOutlined />}
        />
      </Popover>
    </Header>
  );
};

export default AppHeader;