import React from 'react';
import { Result, Button } from 'antd';

const Home: React.FC = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Result
        status="warning"
        title="This page is not available yet"
        subTitle="Sorry, we are working on it."
        extra={
          <Button type="primary" href="/reports">
            Go to Loads
          </Button>
        }
      />
    </div>
  );
};

export default Home;