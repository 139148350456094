import React, { useEffect, useState } from 'react';
import { Card, Table, Spin, Input, Form, Modal, Button, ConfigProvider } from 'antd';
import type { TableProps } from 'antd';
import EditableCell from './EditableCell';
import ReportHistory from './ReportHistory';
import axios from 'axios';
import { lightTheme, darkTheme } from '../../Layout/theme';
import { useNavigate } from 'react-router-dom';
import { render } from '@testing-library/react';

type TablePagination<T extends object> = NonNullable<Exclude<TableProps<T>['pagination'], boolean>>;
type TablePaginationPosition = NonNullable<TablePagination<any>['position']>[number];

interface ReportsTableProps {
    loading: boolean;
    data: any[];
} 

const ReportsTable: React.FC<ReportsTableProps> = ({ loading, data }) => {
    const [top, setTop] = useState<TablePaginationPosition>('topCenter');
    const [bottom, setBottom] = useState<TablePaginationPosition>('bottomCenter');
    const [dataSource, setDataSource] = useState(data);  
    const navigate = useNavigate();

    const isDarkMode = JSON.parse(localStorage.getItem('theme') || 'false');  

    const handleSave = (row: any) => {
        const newData = [...dataSource];
        const index = newData.findIndex(item => row.id === item.id);
        const item = newData[index];
        console.log('row', row);
        console.log('item', item);
        newData.splice(index, 1, { ...item, ...row });
        setDataSource(newData);
    };

    const handleRowClick = (record: any) => {
        // history.push(`/guide/detail/${record.id}`);
        console.log('record', record);
        navigate(`/guide/detail/${record.id}`);
    };

    useEffect(() => {
        setDataSource(data);
    }, [data]);

    const initialStatusData = [   
        { status: 'Unassigned',       count: 0, color: '#ffbf00', selected: false },
        { status: 'Quotes Received',  count: 0, color: '#ffbf00', selected: false }, 
        { status: 'Dispatched',       count: 0, color: '#ffbf00', selected: false }, 
        { status: 'assigned',         count: 0, color: '#00a2ae', selected: false },
        { status: 'in transit',       count: 0, color: '#00a2ae', selected: false },
        { status: 'Completed',        count: 0, color: '#87d068', selected: false },
        { status: 'delivered',        count: 0, color: '#87d068', selected: false }
      ];
      
      const getStatusColor = (status: string) => {
        const statusObj = initialStatusData.find(item => item.status.toLowerCase() === status.toLowerCase());
        return statusObj ? statusObj.color : '#000'; // Default color if status not found
      };
    const defaultColumns = [
        {
            title: 'Load Number',
            dataIndex: 'name',
        },
        // {
        //     title: 'Client',
        //     dataIndex: 'client',
        //     render: (client: any) => <span>{client ? client.name : null}</span>,
        // },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (status: any) => {
                const color = getStatusColor(status.name);
                return (
                  <span>
                    {status ? (
                      <div style={{ background: `${color}1A`, color: color, textAlign: 'center', padding: '5px', borderRadius: '15px', marginLeft: 'auto' }}>
                        {status.name.toUpperCase()}
                      </div>
                    ) : null}
                  </span>
                );
              },
        },
        {
            title: 'Carrier',
            dataIndex: 'carrier',
            render: (carrier: any) => <span>{carrier ? carrier.name : null}</span>,
        },
        {
            title: 'Origin',
            dataIndex: 'origin',
        },
        {
            title: 'Destination',
            dataIndex: 'destiny',
        },
        {
            title: 'Pro number',
            dataIndex: 'pro_number',
        },
        {
            title: 'Reference Dylo',
            dataIndex: 'dylo_reference',
        },
        {
            title: 'Reference Client',
            dataIndex: 'client_reference',
        },
        {
            title: 'Create Date',
            dataIndex: 'created_date',
            render: (text: string | number | Date | null) => {
                if (text !== '1970-01-01T00:00:00.000Z') {
                    if (text !== null) {
                        return new Date(text).toLocaleDateString();
                    } else {
                        return null;
                    }
                } else {
                    return null;
                }
            },
        },
        {
            title: 'ETA',
            dataIndex: 'actual_delivery_date',
            editable: true,
            render: (text: string | number | Date | null) => {
                
                if (text !== '1970-01-01T00:00:00.000Z') {
                    if (text !== null) {
                        return new Date(text).toLocaleDateString();
                    } else {
                        return null;
                    }
                } else {
                    return null;
                }
            },
        } ,
        {
            title: 'Expected ETA',
            dataIndex: 'expected_delivery_date',
            editable: true,
            render: (text: string | number | Date | null) => {
                
                if (text !== '1970-01-01T00:00:00.000Z') {
                    if (text !== null) { 
                        return new Date(text).toLocaleDateString(); 
                    } else {
                        return null;
                    }
                } else {
                    return null;
                }
            },
        } 
    ];

    const columns = defaultColumns.map(col => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: any) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
            }),
        };
    });

    return (
        <ConfigProvider theme={isDarkMode ? darkTheme : lightTheme}>

            <Card style={{ margin: '25px' }}>
                <Spin spinning={loading}>
                    <Table
                        components={{
                            body: {
                                cell: EditableCell,
                            },
                        }}
                        rowKey={(record) => record.id}
                        columns={columns}
                        dataSource={dataSource}
                        rowClassName="editable-row"
                        pagination={{ position: [top] }} 
                        style={{ backgroundColor: isDarkMode ? darkTheme.token.table.rowBackgroundColor : lightTheme.token.table.rowBackgroundColor }}
                        onRow={(record) => ({
                            onClick: () => handleRowClick(record),
                        })}
                    />
                </Spin> 
            </Card>

        </ConfigProvider>
    );
};

export default ReportsTable;