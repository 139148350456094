import React, { useEffect, useState } from 'react';
import { Dropdown, Space, Layout, Menu, Card, Collapse, ConfigProvider } from 'antd';
import {
  FileSearchOutlined,
  PieChartOutlined,
  UserOutlined,
  TeamOutlined,
  FileOutlined,
  DownOutlined
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { lightTheme, darkTheme } from './theme';
import { Link, useLocation } from 'react-router-dom';

const { Sider } = Layout;

type MenuItem = Required<MenuProps>['items'][number] & { label: React.ReactNode };


function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem(<Link to="/">Dashboard</Link>, '1', <PieChartOutlined />),
  getItem(<Link to="/reports">Loads</Link>, '2', <FileSearchOutlined />),
  // getItem('User', 'sub1', <UserOutlined />, [
  //   getItem('Tom', '3'),
  //   getItem('Bill', '4'),
  //   getItem('Alex', '5'),
  // ]),
  // getItem('Team', 'sub2', <TeamOutlined />, [getItem('Team 1', '6'), getItem('Team 2', '8')]),
  // getItem('Files', '9', <FileOutlined />),
];

interface AppSiderProps {
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
}

const AppSider: React.FC<AppSiderProps> = ({ collapsed, setCollapsed }) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const [isDarkMode, setIsDarkMode] = useState(JSON.parse(localStorage.getItem('theme') || 'false'));

  const defaultSelectedKey = items.find(item => {
    const link = item?.label as React.ReactElement;
    return link.props.to === currentPath;
  })?.key;

  useEffect(() => {
    const storedTheme = JSON.parse(localStorage.getItem('theme') || 'false');
    setIsDarkMode(storedTheme);
  }, []);

  const currentTheme = isDarkMode ? darkTheme : lightTheme;

  return (
    <ConfigProvider theme={currentTheme}>
      <Sider
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        style={{ background: currentTheme.token.colorBgContainer }}
      >
        {collapsed ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10px' }}>
            <div style={{ justifyContent: 'center', textAlign: 'center' }}>
              <img alt="Dylo Express Logo" src="/favicon.png" style={{ maxWidth: '70px', maxHeight: '50px' }} />
            </div>
          </div>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10px' }}>
            <div style={{ justifyContent: 'center', textAlign: 'center' }}>
            {isDarkMode ? 
                <img src="/Logotipo - Negativo.png" alt="Logo" style={{ width: '100%' }} /> :
                <img src="/Logotipo - Positivo.png" alt="Logo" style={{ width: '100%' }} />
              }
              {/* <img alt="Dylo Express Logo" src="/DYLO EXPRESS_LOGO_BLANCO_COLOR.png" style={{ maxWidth: '120px' }} /> */}
            </div>
          </div>
        )}
        <Menu
          defaultSelectedKeys={[String(defaultSelectedKey) || '1']}
          mode="inline"
          items={items}
          style={{ background: currentTheme.token.colorBgContainer }}
        />      </Sider>
    </ConfigProvider>
  );
};

export default AppSider;