import React from 'react';
import {   Input, Button, Layout, Typography, ConfigProvider } from 'antd';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import axios from 'axios';
import { lightTheme, darkTheme } from '../../Layout/theme';
import { useNavigate } from 'react-router-dom'; 

const { Search } = Input;

const { Content } = Layout;
const { Title } = Typography;

const url = process.env.REACT_APP_API_URL; 

const Home: React.FC = () => {
    const [isLoading, setIsLoading] = React.useState(false);
    const isDarkMode = JSON.parse(localStorage.getItem('theme') || 'false');
    const currentTheme = isDarkMode ? darkTheme : lightTheme;
    const navigate = useNavigate();

    const handleSearch: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
      const value = event.currentTarget.value;
        setIsLoading(true);
        axios.get(`'https://dylo-app.dylo-microservice.com/api/public-load`, {
          params: {
            search: value
          }
        })
            .then((response) => {
                setIsLoading(false);
                navigate('/public-load', { state: { load: response.data } });
            })
            .catch((error) => {
                setIsLoading(false);
                console.error(error);
            });
    };
  
  return (
    <ConfigProvider theme={currentTheme}>
    <div style={{ alignContent: 'center', height: '100vh', background: currentTheme.token.colorBgBase }}>
      <Layout style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center' }}>
        <Content style={{  width: '40%', padding: '20px', borderRadius: '8px', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)', background: currentTheme.token.colorBgContainer }}>
          <div style={{ textAlign: 'center', marginBottom: '20px' }}>
            {isDarkMode ?
              <img src="/Logotipo - Negativo.png" alt="Logo" style={{ width: '100%' }} /> :
              <img src="/Logotipo - Positivo.png" alt="Logo" style={{ width: '100%' }} />
            }
          </div>
          <Title level={2} style={{ textAlign: 'center', color: currentTheme.token.colorTextBase }}>Search your load</Title>
          <Search placeholder="Press ENTER to search"  enterButton="Search"  size="large" loading={isLoading} onPressEnter={handleSearch}/>  
          
        </Content>
      </Layout>
    </div>
  </ConfigProvider>
  );
};

export default Home;