import React, { useState, useEffect } from 'react';
import { Layout, ConfigProvider, theme } from 'antd';
import AppSider from '../Layout/Sider';
import AppHeader from '../Layout/Header';
import AppFooter from '../Layout/Footer';

interface MainLayoutProps {
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
  children: React.ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ collapsed = true, setCollapsed, children }) => {
  const [isDarkMode, setIsDarkMode] = useState(JSON.parse(localStorage.getItem('theme') || 'false'));

  useEffect(() => {
    const storedTheme = JSON.parse(localStorage.getItem('theme') || 'false');
    setIsDarkMode(storedTheme);
  }, []);

  const currentTheme = isDarkMode ? theme.darkAlgorithm : theme.defaultAlgorithm;

  return (
    <ConfigProvider theme={{ algorithm: currentTheme }}>
      <Layout>
        <AppSider collapsed={collapsed} setCollapsed={setCollapsed} />
        <Layout>
          <AppHeader collapsed={collapsed} setCollapsed={setCollapsed} />
          {children}
          <AppFooter />
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};

export default MainLayout;