// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.status-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 15px;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Reports/StatusCardsCounter.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,6BAA6B;EAC7B,aAAa;EACb,WAAW;AACb","sourcesContent":[".status-cards-container {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-around;\n  padding: 15px;\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
