import React, { useState, useEffect, useRef } from 'react';
import { Input, DatePicker, Select, InputRef } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { set } from 'date-fns';
dayjs.extend(customParseFormat);

const { Option } = Select;

interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: string;
    record: any;
    handleSave: (record: any) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
}) => {
    const inputRef = useRef<InputRef>(null);
    const [value, setValue] = useState<any>(record ? record[dataIndex] : '');

    useEffect(() => {
        if (record && dataIndex) {
            setValue(record[dataIndex]);
        }
    }, [record, dataIndex]);

    const validateDate = (dateString: string | number | dayjs.Dayjs | Date | null | undefined, format: dayjs.OptionType | undefined = 'YYYY-MM-DD') => {
        const date = dayjs(dateString, format, true);
        return date.isValid() && date.format(format.toString()) === dateString;
    };

    const handleChange = (newValue: any) => {
        // console.log('handleChange', newValue);
        if (dataIndex === 'eta_real') {
            setValue(newValue);
            handleSave({ ...record, [dataIndex]: newValue });
        } else {
            handleSave({ ...record, [dataIndex]: newValue });
            setValue(newValue);
        }

    };

    const handleBlur = () => {

        setValue(value);

    };

    let childNode = children;

    if (editable = false) {
        if (dataIndex === 'eta_real') {
            childNode = (
                <DatePicker
                    ref={(inputRef as React.RefObject<any>)}
                    value={value ? dayjs(value) : null}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    format="YYYY-MM-DD"
                />
            );
        } else if (dataIndex === 'estatus') {
            childNode = (
                <Select
                    ref={inputRef as any}
                    value={value}
                    onChange={handleChange}
                    onBlur={handleBlur}
                >
                    <Option value="CREADA">CREADA</Option>
                    <Option value="EN TRANSITO">EN TRANSITO</Option>
                    <Option value="CANCELADA">CANCELADA</Option>
                    <Option value="DEVOLUCION">DEVOLUCION</Option>
                    <Option value="ENTREGADO">ENTREGADO</Option>
                    <Option value="INCIDENCIA">INCIDENCIA</Option>
                    <Option value="OFICINA">OFICINA</Option>
                    <Option value="PROGRAMADO">PROGRAMADO</Option>
                </Select>
            );
        } else {
            childNode = (
                <Input
                    ref={inputRef}
                    value={value}
                    onChange={(e) => handleChange(e.target.value)}
                    onPressEnter={handleBlur}
                    onBlur={handleBlur}
                />
            );
        }
    }

    return <td {...restProps}>{childNode}</td>;
};

export default EditableCell;