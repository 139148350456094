import React, { useEffect, useState } from 'react';
import StatusCardsCounter from './StatusCardsCounter';
import ReportsTable from './ReportsTable';
import ReportsFilters from './ReportsFilters';
import axios from 'axios';
import moment from 'moment';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import { notification } from 'antd';
import { useNavigate } from 'react-router-dom';

const Reports: React.FC = () => {
  const initialStatusData = [
    { status: 'Total', count: 0, color: '', selected: false },
    { status: "Assigned", count: 0, color: '#00a2ae', selected: false },
    { status: 'In Transit', count: 0, color: '#00a2ae', selected: false },
    { status: 'Completed', count: 0, color: '#87d068', selected: false },
    { status: 'Delivered', count: 0, color: '#87d068', selected: false }
  ];

  const navigate = useNavigate();

  const [statusData, setStatusData] = useState(initialStatusData);
  const [reportsTableData, setReportsTableData] = useState<any>([]);
  const [datePickerValue, setDatePickerValue] = useState<any>();
  const [selectedCarriers, setSelectedCarriers] = useState<string[]>([]);
  const [selectedCompanies, setSelectedCompanies] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await Promise.all([getStatusCards(), getReportTable()]);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    const timer = setTimeout(fetchData, 1500);
    return () => clearTimeout(timer);
  }, [searchValue, datePickerValue, selectedCarriers, selectedCompanies]);

  const handleCardClick = async (status: string) => {
    setStatusData(prevData =>
      prevData.map(item =>
        item.status === status ? { ...item, selected: !item.selected } : item
      )
    );
  };

  useEffect(() => {
    getReportTable();
  }, [statusData]);

  const tokenExpired = () => {
    notification.warning({
      message: 'Access Failed',
      description: 'Expire or invalid credentials. Login again.',
      showProgress: true,
      pauseOnHover: true,
    });
    localStorage.removeItem('tokens');
    localStorage.removeItem('users');
    navigate('/login');
  };

  const getStatusCards = async () => {
    const token = JSON.parse(localStorage.getItem('tokens') || '[]')[0];
    try {
      const response = await axios.get('https://dylo-app.dylo-microservice.com/api/status-loads-cards', {
        params: {
          mounth: datePickerValue,
          search: searchValue,
          carriers: selectedCarriers
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      console.log('item', response.data);
      const updatedStatusData = statusData.map(item => {
        const matchingStatus = response.data.find((statusItem: any) => statusItem.Estatus === item.status);
        return matchingStatus ? { ...item, count: matchingStatus.count } : { ...item, count: 0 };
      });

      if (updatedStatusData.length > 0) {
        const firstItem = updatedStatusData[0];
        for (let i = 1; i < updatedStatusData.length; i++) {
          firstItem.count += updatedStatusData[i].count;
        }
      }

      setStatusData(updatedStatusData);
    } catch (error: any) {
      if (error.response?.status === 401) {
        tokenExpired();
      } else if (error.response) {
        notification.warning({
          message: error.response?.status,
          description: error.response?.message,
          showProgress: true,
          pauseOnHover: true,
        });
      }
      console.error('Error fetching status data:', error);
    }
  };

  const getReportTable = async () => {
    const token = JSON.parse(localStorage.getItem('tokens') || '[]')[0];
    const selectedStatuses = statusData
      .filter(item => item.selected)
      .map(item => item.status);

    try {
      console.log({
        params: {
          mounth: datePickerValue,
          search: searchValue,
          carriers: selectedCarriers,
          selectedStatuses: selectedStatuses
        }
      });
      const response = await axios.get('https://dylo-app.dylo-microservice.com/api/loads-table', {
        params: {
          mounth: datePickerValue,
          search: searchValue,
          carriers: selectedCarriers,
          selectedStatuses: selectedStatuses
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      console.log('response', response);
      setReportsTableData(response.data);
    } catch (error: any) {
      if (error.response.status === 401) {
        tokenExpired();
      } else if (error.response) {
        notification.warning({
          message: error.response?.status,
          description: error.response?.message,
          showProgress: true,
          pauseOnHover: true,
        });
      }
      console.error('Error fetching report table data:', error);
    }
  };

  const generateReport = async () => {
    const token = JSON.parse(localStorage.getItem('tokens') || '[]')[0];
    try {
      const response = await axios.get('https://dylo-app.dylo-microservice.com/api/generate-report', {
        params: {
          mounth: datePickerValue,
          search: searchValue,
          carriers: selectedCarriers,
          selectedStatuses: statusData
            .filter(item => item.selected)
            .map(item => item.status)
        },
        headers: {
          'Content-Type': 'application/json',
          'authorization': `Bearer ${token}`
        }
      });
      console.log('response', response);

      if (typeof response.data === 'string') {
        const parsedData = Papa.parse(response.data, { header: true });
        if (parsedData.errors.length) {
          console.error('Error parsing CSV:', parsedData.errors);
        } else {
          generateExcel(parsedData.data);
        }
      } else {
        console.error('Error: La respuesta no es una cadena CSV');
      }

    } catch (error: any) {
      if (error.response.status === 401) {
        tokenExpired();
      } else if (error.response) {
        notification.warning({
          message: error.response?.status,
          description: error.response?.message,
          showProgress: true,
          pauseOnHover: true,
        });
      }
      console.error('Error generating report:', error);
    }
  };

  const generateExcel = (data: any) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    let date = moment().format('YYYY-MM-DD');
    saveAs(blob, 'report-' + date + '.xlsx');
  };

  return (
    <div>
      <StatusCardsCounter statusData={statusData} handleCardClick={handleCardClick} loading={loading} />
      <ReportsFilters
        datePickerValue={datePickerValue}
        setDatePickerValue={setDatePickerValue}
        selectedCarriers={selectedCarriers}
        setSelectedCarriers={setSelectedCarriers}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        loading={loading}
        generateReport={generateReport}
      />
      <ReportsTable
        loading={loading}
        data={reportsTableData} />
    </div>
  );
};

export default Reports;