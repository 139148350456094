import React, { useEffect, useState } from 'react';
import { notification, Steps, Card, Row, Col, Typography, Tag, Timeline, Divider, Space, Collapse, Empty, Input, Button } from 'antd';
import { FilePdfTwoTone, LoadingOutlined, SmileOutlined, FileDoneOutlined, HistoryOutlined } from '@ant-design/icons';
import { lightTheme, darkTheme } from '../../Layout/theme';
import { ConfigProvider } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import FileUpload from './FileUpload';
import ReactQuill from 'react-quill';
import { useNavigate } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css'; // Importa los estilos de Quill


import axios from 'axios';

const { Title, Text } = Typography;

const GuideDetail: React.FC = () => {
  const isDarkMode = JSON.parse(localStorage.getItem('theme') || 'false');
  const users = localStorage.getItem('users');
  const user = users ? JSON.parse(users)[0] : false;
  const { id } = useParams<{ id: string }>();
  const [loadDetails, setLoadDetails] = useState<any>({});
  const [editorContent, setEditorContent] = useState('');
  const [dyloReference, setDyloReference] = useState('');
  const [clientReference, setClientReference] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    fetchLoadDetails();
  }, [id]);
  const fetchLoadDetails = async () => {
    const token = JSON.parse(localStorage.getItem('tokens') || '[]')[0]; 
    try {
      const response = await axios.get(`https://dylo-app.dylo-microservice.com/api/loads/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      await setLoadDetails(response.data);
      console.log('Load details:', response.data);
    } catch (error: any) {
      if (error.response.status === 401) {
        notification.warning({
          message: 'Access Failed',
          description: 'Expire or invalid credentials. Login again.',
          showProgress: true,
          pauseOnHover: true,
        });
        localStorage.removeItem('tokens');
        localStorage.removeItem('users');
        navigate('/login');
      } 
      if (error.response.status === 404) {
        notification.warning({
          message: 'Load not found',
          description: 'This load does not exist.',
          showProgress: true,
          pauseOnHover: true,
        }); 
      }  
      console.error('Error fetching load details:', error);
    }
  };

  useEffect(() => {
    const updateReferences = async () => {
      setDyloReference(loadDetails.dylo_reference);
      setClientReference(loadDetails.client_reference);
    };
    updateReferences();
  }, [loadDetails]);

  const collapseLoadDetails = loadDetails.lineItems?.map((item: any, index: number) => (
    <div key={index}>
      <Divider orientation="left">  {item.name}</Divider>
      <p>Handling count: {item.handling_unit_count} ({item.handling_units})</p>
      <p>Dimensions (L x W x H): {item.length} x {item.width} x {item.height} ({item.dimension_units})</p>
      <p>Weight: {item.weight} ({item.weight_units})</p>
      <p>Description: {item.description}</p>
    </div>
  ));

  const renderJson = (json: any) => {
    if (typeof json !== 'object' || json === null) {
      return <span>{String(json)}</span>;
    }

    return (
      <ul>
        {Object.entries(json).map(([key, value]) => (
          <li key={key}>
            <strong>{key}:</strong> {renderJson(value)}
          </li>
        ))}
      </ul>
    );
  };

  const historyItems = loadDetails.histories?.map((history: any, index: number) => {
    let formattedJson;
    try {
      const jsonObject = JSON.parse(history.changes);
      formattedJson = jsonObject; // Mantener el objeto JSON
    } catch (error) {
      formattedJson = 'Invalid JSON string';
    }

    const formattedDate = format(new Date(history.createdAt), 'dd/MM/yyyy HH:mm');

    return (
      <Timeline.Item key={index}>
        <p>{formattedDate}</p>
        {typeof formattedJson === 'string' ? (
          <pre>{formattedJson}</pre>
        ) : (
          renderJson(formattedJson)
        )}
      </Timeline.Item>
    );
  });


  const notesItems = loadDetails.notes?.slice().reverse().map((note: any, index: number) => {
    const formattedDate = format(new Date(note.createdAt), 'dd/MM/yyyy HH:mm');
    return (
      <Timeline.Item key={index}>
        <Card>
          <p>{formattedDate}</p>
          <div dangerouslySetInnerHTML={{ __html: note.note }} />
        </Card>
      </Timeline.Item>
    );
  });


  const getStepStatus = (statusId: number): ("finish" | "wait" | "process" | "error" | undefined)[] => {
    switch (statusId) {
      case 1: // Delivered
      case 3: // Completed
        return ['finish', 'finish', 'finish', 'finish'];
      // case 7: // Quotes received
      // case 9: // Unassigned
      //   return ['process', 'wait', 'wait', 'wait'];
      case 2: // In transit
        return ['finish', 'finish', 'process', 'wait'];
      case 4: // assigned
        return ['finish', 'process', 'wait', 'wait'];
      default:
        return ['wait', 'wait', 'wait', 'wait'];
    }
  };

  const stepStatus = getStepStatus(loadDetails.status_id);

  const uploadNote = async () => {
    try {
      const token = JSON.parse(localStorage.getItem('tokens') || '[]')[0];
      console.log('Uploading note:', editorContent);
      const response = await axios.post('https://dylo-app.dylo-microservice.com/api/add-notes', {
        load_id: id,
        content: editorContent,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      fetchLoadDetails();
      console.log('Note uploaded:', response.data);
      setEditorContent('');
    } catch (error) {
      console.error('Error uploading note:', error);
    }
  }

  const updateReference = async (e: any, type: string) => {
    console.log('Updating ', type, ' updated:', e.target.value);
    const value = e.target.value;
    if (type === 'Dylo') {
      setDyloReference(value);
    } else if (type === 'Client') {
      setClientReference(value);
    }
    try {
      const token = JSON.parse(localStorage.getItem('tokens') || '[]')[0];
      const url = process.env.REACT_APP_API_URL;
      const response = await axios.post('https://dylo-app.dylo-microservice.com/api/update-reference', {
        load_id: id,
        reference: e.target.value,
        type: type,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      fetchLoadDetails();
      console.log('Reference updated:', response.data);
    } catch (error) {
      console.error('Error updating reference:', error);
    }
  }

  return (
    <ConfigProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <div style={{ margin: '0 auto', paddingInline: '20px', paddingTop: '20px' }}>
        <div className="" style={{ display: 'flex' }}>
          <Card style={{ flex: 1, margin: 15 }}>
            <div style={{ display: 'flex' }}>
              <Space align="baseline" size={100}>
                <div style={{ flex: 1 }}>
                  <h1>Pro number: {loadDetails.pro_number}</h1>
                </div>
                {/* <div style={{ flex: 1 }}>
                  {
                    loadDetails.pickup_carrier_number ? (
                      <h3>Pickup number: {loadDetails.pickup_carrier_number}</h3>
                    ) : null
                  }
                </div>
                <div style={{ flex: 1 }}>
                  {
                    loadDetails.pro_number ? (
                      <
                    h3>Pro number: {loadDetails.pro_number}</h3>
                    ) : null
                  } */}
                {/* <Link to={`https://www.sefl.com/webconnect/tracing?Type=PN&RefNum1=${loadDetails.pro_number}`} target="_blank">
                    <h3>Pro number: {loadDetails.pro_number}</h3>
                  </Link> */}
                {/* </div> */}
              </Space>
              <div style={{ background: '#87d0681A', color: '#87d068', textAlign: 'center', padding: '5px', borderRadius: '15px', marginLeft: 'auto' }}>
                <h3 style={{ flex: 1, padding: '5px' }}>{loadDetails.status?.name.toUpperCase()} {
                  loadDetails.updatedAt && loadDetails.updatedAt !== '1970-01-01T00:00:00.000Z' ? (new Date(loadDetails.updatedAt).toLocaleDateString()) : null
                } {
                    loadDetails.actual_delivery_date !== '1970-01-01T00:00:00.000Z' && loadDetails.actual_pickup_date !== '1970-01-01T00:00:00.000Z' ? <>- Transit days: {(new Date(loadDetails.actual_delivery_date).getTime() - new Date(loadDetails.actual_pickup_date).getTime()) / (1000 * 60 * 60 * 24)}</> : null
                  }
                </h3>
              </div>
            </div>
          </Card>
        </div>
        <div style={{ margin: '0 auto', paddingInline: '20px' }}>
          <Steps
            items={[
              {
                title: 'Pending',
                status: stepStatus[0],
                icon: <HistoryOutlined />,
              },
              {
                title: 'Assigned',
                status: stepStatus[1],
                icon: <FileDoneOutlined />,
              },
              {
                title: 'In transit',
                status: stepStatus[2],
                icon: <LoadingOutlined />,
              },
              {
                title: 'Delivered',
                status: stepStatus[3],
                icon: <SmileOutlined />,
              },
            ]}
          />
        </div>
        <div className="" style={{ display: 'flex' }}>
          <div style={{ flex: 2, margin: 15 }}>
            <div style={{ display: 'flex' }}>
              <Card style={{ flex: 1, marginBottom: 15, marginRight: 7, textAlign: 'center', fontSize: '1.5em' }}>
                <strong> Origin: </strong> {loadDetails.origin}
              </Card>
              <Card style={{ flex: 1, marginBottom: 15, marginLeft: 7, textAlign: 'center', fontSize: '1.5em' }}>
                <strong> Destination: </strong> {loadDetails.destiny}
              </Card>
            </div>
            <div style={{ display: 'flex' }}>
              <Card style={{ flex: 1, padding: '20px' }}>
                <div style={{ display: 'flex' }}>
                  <div style={{ flex: 1, textAlign: 'center', fontSize: '1.4em' }}>
                    <Row gutter={[16, 16]}>
                      <Col span={12} style={{ textAlign: 'left', fontSize: '1.4em' }}>
                        <strong>Carrier:</strong> <Text code type='danger' style={{ fontSize: '1em' }}>{loadDetails.carrier_name}</Text>
                      </Col>
                      <Col span={12} style={{ textAlign: 'left', fontSize: '1.4em' }}>
                        <strong>Expected Delivery Date :</strong> <Text code type='danger' style={{ fontSize: '1em' }}>{
                          loadDetails.expected_delivery_date !== '1970-01-01T00:00:00.000Z' ? (new Date(loadDetails.expected_delivery_date).toLocaleDateString()) : null
                        }</Text>
                      </Col>
                      <Col span={12} style={{ textAlign: 'left', fontSize: '1.4em' }}>
                        <strong>Mode:</strong> {loadDetails.mode_name}
                      </Col>
                      <Col span={12} style={{ textAlign: 'left', fontSize: '1.4em' }}>
                        <strong>Dispatcher:</strong> {loadDetails.dispatcher_name}
                      </Col>
                      <Col span={12} style={{ textAlign: 'left', fontSize: '1.4em' }}>
                        <strong>Billing Reference:</strong> {loadDetails.billing_reference}
                      </Col>
                      <Col span={12} style={{ textAlign: 'left', fontSize: '1.4em' }}>
                        <strong>PO Number:</strong> {loadDetails.po_number}
                      </Col>
                      <Col span={12} style={{ textAlign: 'left', fontSize: '1.4em' }}>
                        <strong>Reference Dylo:</strong>
                        {
                          user?.id === 'a2f9fc42-9799-4c36-b1e3-91f0f90d83af' ? (<>
                            <Input
                              value={dyloReference}
                              onChange={(e) => setDyloReference(e.target.value)}
                              onBlur={(e) => { updateReference(e, 'Dylo') }}
                            />

                          </>) :
                            <>{dyloReference}</>}
                      </Col>
                      <Col span={12} style={{ textAlign: 'left', fontSize: '1.4em' }}>
                        <strong>Reference Client:</strong>

                        {
                          user?.id === 'a2f9fc42-9799-4c36-b1e3-91f0f90d83af' ? (<>
                            <Input
                              value={clientReference}
                              onChange={(e) => setClientReference(e.target.value)}
                              onBlur={(e) => { updateReference(e, 'Client') }}
                            />

                          </>) :
                            <>{clientReference}</>}
                      </Col>
                    </Row>
                  </div>
                </div>
              </Card>
            </div>
          </div>
          <div style={{ flex: 1, margin: 15 }}>
            <div style={{ flex: 1 }}>
              <Card style={{ flex: 1, marginBottom: 15 }}>
                <Collapse
                  ghost
                  items={[
                    {
                      key: '1',
                      label: 'Documents',
                      children: (
                        <>
                          {loadDetails.document?.length > 0 ? (
                            <Space direction="vertical" size="middle" style={{ display: 'flex', marginBottom: 15 }}>
                              {loadDetails.document?.map((doc: any, index: number) => (
                                <p key={index}>
                                  <FilePdfTwoTone twoToneColor="#eb2f96" style={{ marginRight: '1em', marginLeft: '1em' }} />
                                  <Link to={doc.file} target="_blank">
                                    <Text italic>{doc.name}</Text>
                                  </Link>
                                </p>
                              ))}
                            </Space>) : (<Empty />)}
                          {
                            user?.id === 'a2f9fc42-9799-4c36-b1e3-91f0f90d83af' ? (<>
                              <Divider > </Divider>
                              <FileUpload id={loadDetails.id} />
                            </>) : null}
                        </>
                      ),
                    },
                  ]}
                />
              </Card>
            </div>
            <div style={{ flex: 1 }}>
              <Card style={{ flex: 1, marginBottom: 15 }}>
                <Collapse ghost items={[{ key: 'Load-Details-1', label: 'Load Details', children: collapseLoadDetails }]} />
              </Card>
            </div>
            <div style={{ flex: 1 }}>
              <Card style={{ flex: 1, marginBottom: 15 }}>
                <Collapse ghost items={[{
                  key: 'Accessorials-1', label: 'Accessorials', children:
                    (<>
                      {
                        loadDetails.accessorial?.length > 0 ? (
                          <div >
                            {loadDetails.accessorial?.map((acc: any, index: number) => (
                              <p key={index}>
                                <Divider orientation="left">Accesorial {acc.name}</Divider>
                                <p>Accesorial: {acc.FullName}</p>
                                <p>Type: {acc.accessorial_type}</p>
                              </p>
                            ))}
                          </div>
                        ) : (<Empty />)}
                    </>)
                }]} />
              </Card>
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <div style={{ margin: '0 auto', paddingLeft: '20px', flex: 1 }}>
          <Divider orientation="left">Notes</Divider>
          <Card style={{ flex: 1, margin: 15 }}>
            {
              user?.id === 'a2f9fc42-9799-4c36-b1e3-91f0f90d83af' ? (<>
                <Divider orientation="left">Add note</Divider>
                <ReactQuill theme="snow" value={editorContent} onChange={setEditorContent} style={{ borderBlock: 'none' }} />
                <Button type="primary" block onClick={uploadNote}> Add note </Button>
              </>) : null
            }

            <Divider orientation="left" style={{ paddingTop: 10 }}>History notes</Divider>
            {loadDetails.notes?.length > 0 ? <Timeline>{notesItems}</Timeline> : <Empty />}

          </Card>
        </div>
        <div style={{ margin: '0 auto', paddingRight: '20px', paddingLeft: '10px', flex: 1 }}>
          <Divider orientation="left">History</Divider>
          <Card style={{ flex: 1, margin: 15 }}>
            {loadDetails.histories?.length > 0 ? <Timeline>{historyItems}</Timeline> : <Empty />}
          </Card>
        </div>

      </div>
    </ConfigProvider>
  );
};

export default GuideDetail;