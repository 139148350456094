// PrivateRoute.tsx
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { notification } from 'antd';

const PrivateRoute: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      notification.error({
        message: 'Authentication Error',
        description: 'No active session or your session has expired',
      });
      window.location.href = ('/login');
    }
  }, [isAuthenticated, navigate]);

  if (!isAuthenticated) {
    return null; // Renderiza nada mientras redirige
  }

  return children;
};

export default PrivateRoute;