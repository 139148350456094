import React, { useState } from 'react';
import { Card, Spin } from 'antd';
import './StatusCardsCounter.css';

interface StatusData {
    status: string;
    count: number;
    color: string;
    selected: boolean;
}

interface StatusCardsCounterProps {
    statusData: StatusData[]; 
    handleCardClick: (status: string) => void;
    loading: boolean;
}

const StatusCardsCounter: React.FC<StatusCardsCounterProps> = ({ statusData,  handleCardClick, loading }) => {

    const [isDarkMode] = useState(JSON.parse(localStorage.getItem('theme') || 'false'));
    return (
        <Spin spinning={loading}>
            <div className="status-cards-container"  >
                {statusData.map((item) => (
                    <Card
                        key={item.status}
                        title={item.status.toUpperCase()}
                        style={{
                            backgroundColor: item.selected
                            ? `${item.color}1A`
                            : isDarkMode
                            ? '#ffffff1A'
                            : '#ffffff',
                            color: item.color, 
                            margin: 10, 
                            flex: 1,
                            textAlign: 'center',
                            cursor: 'pointer'
                        }}
                        headStyle={{ color: item.color }}
                        onClick={() => handleCardClick(item.status)}
                    >
                        <div style={{ textAlign: 'center' }}>
                            <label style={{ fontSize: '24px' }}>{item.count}</label>
                        </div>
                    </Card>
                ))}
            </div>
        </Spin>
    );
};

export default StatusCardsCounter;